import * as React from 'react';
import { graphql } from 'gatsby';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import CMSPage from '../../components/Content/CMSPage';
import { Metadata } from '@mangoart/gatsby-ui/components/Metadata';

function UeberUnsPage({ data, location }) {
  const { page, site } = data;
  const { siteMetadata } = site;
  const { metadata, opengraph } = page;

  return (
    <DefaultLayout>
      <div className="wrap">
        <CMSPage content={page} />
        <Metadata
          data={metadata}
          opengraphData={opengraph}
          defaultTitle={siteMetadata.title}
          canonicalSiteUrl={siteMetadata.canonicalSiteUrl}
          path={location.pathname}
        />
      </div>
    </DefaultLayout>
  );
}

export default UeberUnsPage;

export const UeberUnsPageQuery = graphql`
  query UeberunsContent($language: String) {
    site: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    page: cockpitRegionUnternehmen(lang: { eq: $language }) {
      title
      teaser
      identifier
      cssclass
      content
      images {
        publicURL
        childImageSharp {
          gatsbyImageData
        }
      }
      metadata {
        title
        metakeywords
        metadescription
      }
      opengraph {
        title
        description
        type
        image {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
